/**
 * Cantidad de explotaciones asignadas a partir de las cuales se muestra el buscador
 **/
export const limiteBuscadorExplotaciones = 10;
export const tokenCCAString = 'Tokencca';
export const apiV1 = 'v1';
/**
 * Formato usado para campos de fecha y hora
 */
export const fechaHoraFormat = 'DD/MM/YYYY HH:mm';

/**
 * Formato usado para campos de fecha
 */
export const fechaFormat = 'DD/MM/YYYY';

export const fechaFormatApi = 'YYYY-MM-DD';

/**
 * Formato usado para fechas de notificaciones
 */
export const fechaNotificacionFormat = 'YYYY-MM-DD HH:mm';

/**
 * Datos de medios de soporte a clientes
 */
export const telefonoSoporteYWhatsapp = '649 943 927';
export const emailSoporte = 'ayuda@globalcampo.es';

