import { Inject, Injectable, Injector, reflectComponentType, Type } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoggerAlertService } from './alerts/alert.logger.service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { ILogEntry, IRequestOptions } from '../interfaces/log/options.interface';
import { IPostObject } from '../interfaces/log/post-log.interface';
import { LOGGER_PROVIDER } from './logger/logger-provider.token';
import { ILoggerProvider } from './logger/logger-provider.interface';
import { catchError, of } from 'rxjs';
import { logsEndPoints } from '../enpoints/logs.endpoint.v1';

@Injectable({
  providedIn: 'root',
})
export class RegistroLoggerService {

  private readonly http: HttpClient;
  private static readonly HTTP_OK = 200;

      constructor(private readonly router: Router,
        @Inject(LOGGER_PROVIDER) private readonly logger: ILoggerProvider,
        private readonly activatedRoute: ActivatedRoute,
        private readonly injector: Injector) {
        this.http = this.injector.get(HttpClient);
        }

  logState<T>(context: string, request: HttpRequest<T>, response: HttpResponse<T> | HttpErrorResponse, requestOptions: IRequestOptions, interceptorStart: number): void {
    const currentRoute = this.getCurrentRouteInfo();
    const { criticalStatus, logEmptyContent } = requestOptions;
    const interceptorEnd = performance.now();
    const latencia = Math.round(interceptorEnd - interceptorStart);

    // Manejo directo de errores
    if (response instanceof HttpErrorResponse) {
      this.executeActions(context, request, response, requestOptions, latencia, currentRoute);
      return;
    }
    // Verificar si es un estado crítico
    if (response instanceof HttpResponse && response.status === criticalStatus) {
      this.executeActions(context, request, response, requestOptions, latencia, currentRoute);
      return;
    }
     // Verificar si no es un error y logEmptyContent está activado y la respuesta está vacía
    if (logEmptyContent && response instanceof HttpResponse && this.isEmptyContent(response)) {
      this.executeActions(context, request, response, requestOptions, latencia, currentRoute);
    }

  }

  private getCurrentRouteInfo(): { url: string; component: string } {
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const component = this.getComponentName(route.component);

    const url = this.router.url;
    return { url, component };
  }

  private getComponentName(component: Type<unknown> | null) {
    const unknownComponent = 'Unknown Component';
    try {
      let componentName = component?.name ?? unknownComponent;
      if (component) {
        const metadata = reflectComponentType(component);
        if (metadata?.selector) {
          componentName = metadata.selector;
        }
      }
      return componentName;
    } catch {
      return unknownComponent;
    }
  }

  private isEmptyContent<T>(response: HttpResponse<T>): boolean {
    return (
      response.status === RegistroLoggerService.HTTP_OK &&
      (response.body === null || response.body === undefined || Object.keys(response.body).length === 0)
    );
  }

  private executeActions<T>(
    context: string,request: HttpRequest<T>,
    response: HttpResponse<T> | HttpErrorResponse,
    requestOptions: IRequestOptions,
    latencia: number,
    currentRoute: { url: string; component: string }): void {

    const logEntry: ILogEntry = {
      context,
      request,
      response,
      responseTime: latencia,
      timestamp: new Date(),
      currentUrl: currentRoute.url,
      componentName: currentRoute.component,
      options: requestOptions,
    };

    if (requestOptions.action === 'stop') {
      this.logger.log('Parar:');
    }

    if (requestOptions.toConsole) {
      if (response instanceof HttpErrorResponse) {
        this.logger.log('Error fatal:', logEntry);
      } else if (response.status === requestOptions.criticalStatus) {
        this.logger.log('Error marcado:', logEntry);
      } else {
        this.logger.log('Error vacío:', logEntry);
      }
    }

    if (requestOptions.notifyUser) {
      const loggerAlertService = this.injector.get(LoggerAlertService);
      loggerAlertService.mostrarAlertaDeError();
    }

    this.postLog(logEntry);
  }

  private postLog(logEntry: ILogEntry): void {
    try {
      const postObject = this.buildPostObject(logEntry);

      const requestOptions: IRequestOptions = { monitor: false };
      const headers = new HttpHeaders({
        'GCLogRequest': JSON.stringify(requestOptions),
      });

      if (postObject.urlBack !== logsEndPoints.postLogs) {
        this.http.post(logsEndPoints.postLogs, postObject, { headers })
        .pipe(
          catchError(() => of(null)),
        )
        .subscribe({
          next: () => {},
        });
      }
    } catch (e) {
    }
  }

  private buildPostObject(logEntry: ILogEntry): IPostObject {
    return {
      codStatus: logEntry.response.status,
      respuestaBack: JSON.stringify(logEntry.request),
      respuestaFront: JSON.stringify(logEntry.response),
      tiempo: logEntry.responseTime,
      observaciones: JSON.stringify(logEntry),
      componenteFront: logEntry.componentName,
      tokenCCA: logEntry.request.headers.get('tokencca'),
      metodo: logEntry.request.method,
      urlBack: logEntry.request.url,
      urlFront: logEntry.currentUrl,
    };
  }

}
