import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, from, of, switchMap, tap } from 'rxjs';
import { StorageKeys } from '../../config/storage-keys.config';
import { urls } from '../../config/urls';
import { EMessageTypes } from '../../enums/message-type.enum';
import { IAltaExplotacionData } from '../../interfaces/explotaciones/alta-explotacion-data';
import { IExplotacionAmpliado, IInstalacion, IInstalacionAmpliado } from '../../interfaces/instalaciones/instalacion.interface';
import { IDatosToken } from '../../interfaces/security/datos-token.interface';
import { AlertService } from '../alerts/alert.service';
import { AppStatusService } from '../app-status.service';
import { ExplotacionesService } from '../cuadernos-de-campo/explotaciones.service';
import { GlobalcampoService } from '../cuadernos-de-campo/globalcampo.service';
import { LoadingService } from '../loading/loading.service';
import { IStorageProvider } from '../storage/storage-provider.interface';
import { STORAGE_PROVIDER } from '../storage/storage-provider.token';

export const redireccionAutomaticaInstalacionesGuard: CanActivateFn = () => new Promise<boolean>((resolve) => {
  const explotaciones: ExplotacionesService = inject(ExplotacionesService);
  const app: AppStatusService = inject(AppStatusService);
  const globalCampo: GlobalcampoService = inject(GlobalcampoService);
  const router: Router = inject(Router);
  const alert: AlertService = inject(AlertService);
  const translate: TranslateService = inject(TranslateService);
  const loading: LoadingService = inject(LoadingService);
  const storage = inject<IStorageProvider>(STORAGE_PROVIDER);

  const cargando = translate.instant('MODAL.LOADING.ACCEDIENDO_A_CUADERNO');
  const getInstalacionesAmpliado = loading.loadWithMessage(globalCampo.getInstalacionesAmpliado(), cargando);

  getInstalacionesAmpliado.pipe(
    switchMap((instalaciones) => {
      if(instalaciones) {
        return from(redirectHasInstalaciones(instalaciones, { alert, translate, app, explotaciones, router, globalCampo, storage }));
      } else {
        router.navigateByUrl(urls.cca.alta_explotacion);
        return of(true);
      }
    }),
    tap((result) => resolve(result ?? false)),
  ).subscribe({
    error: () => {
      alert.openAlert(EMessageTypes.error, {
        header: 'Error',
        message: 'Se ha producido un error inesperado, por favor, inténtelo de nuevo.',
        actionButton: () => alert.dismiss(),
        actionButtonLabel: translate.instant('GENERAL.CERRAR'),
      });
      router.navigateByUrl(urls.inicio);
      resolve(false);
    },
  });
});

interface RedirectParams {
  alert: AlertService;
  translate: TranslateService;
  app: AppStatusService;
  explotaciones: ExplotacionesService;
  router: Router;
  globalCampo: GlobalcampoService;
  storage: IStorageProvider;
}

/**
 * Redirige al usuario en función de las instalaciones proporcionadas y los parámetros.
 *
 * @param instalaciones - Un array de objetos IInstalacionAmpliado que representan las instalaciones.
 * @param params - Un objeto que contiene varios parámetros necesarios para la redirección.
 * @param params.alert - El servicio de alertas para mostrar alertas.
 * @param params.translate - El servicio de traducción para traducir mensajes.
 * @param params.app - El objeto de estado de la aplicación.
 * @param params.explotaciones - El servicio de explotaciones para manejar explotaciones.
 * @param params.router - El servicio de enrutamiento para la navegación.
 * @param params.globalCampo - El servicio globalCampo para operaciones globales.
 * @param params.storage - El servicio de almacenamiento para manejar operaciones de almacenamiento.
 * @returns Una promesa que se resuelve en un booleano que indica si ocurrió una redirección.
 */
async function redirectHasInstalaciones(instalaciones: IInstalacionAmpliado[], params: RedirectParams): Promise<boolean> {
  const { alert, translate, app, explotaciones, router, globalCampo, storage } = params;

  if (instalaciones.length === 1) {
    const firstInstalacion = instalaciones[0];
    app.cantidadExplotaciones = firstInstalacion.explotaciones?.length ?? 0;
    await selectInstalacion(firstInstalacion, alert, translate, app, explotaciones);
    const explotacionesEnInstalacion = firstInstalacion.totalExplotaciones;
    if (explotacionesEnInstalacion === 0 || (explotacionesEnInstalacion === 1 && await checkAltaExplotacion(firstInstalacion, globalCampo, storage))) {
      router.navigateByUrl(urls.cca.alta_explotacion);
      return true;
    }
    if (explotacionesEnInstalacion === 1) {
      const isNavigate = await setTokenComplete(firstInstalacion, firstInstalacion.explotaciones[0].codIdentific, explotaciones, app, alert, translate);
      if (isNavigate) {
        router.navigateByUrl(urls.cca.dashboard);
      }
      return isNavigate;
    } else {
        router.navigateByUrl(urls.cca.busqueda);
        return true;
    }
  }
  if (instalaciones.length === 0) {
    router.navigateByUrl(urls.cca.alta_explotacion);
    return true;
  }
  if (instalaciones.length > 1) {
    return true;
  }

  return false;
}

/**
 * Selects an installation and sets the current installation in the application status.
 *
 * @param el - The installation to be selected.
 * @param alert - The alert service to show error messages.
 * @param translate - The translation service for localizing messages.
 * @param app - The application status service to update the current installation.
 * @param explotaciones - The service to handle installation-related operations.
 * @returns A promise that resolves to `true` if the installation was successfully selected, or `false` if an error occurred.
 */
async function selectInstalacion(el: IInstalacion, alert: AlertService, translate: TranslateService,
  app: AppStatusService, explotaciones: ExplotacionesService): Promise<boolean> {
    try {
      await setTokenInstalacion(el, explotaciones, app);
      app.setCurrentInstalacion(el);
      app.cantidadExplotaciones = el.totalExplotaciones ?? 0;
    } catch (error) {
      showTokenErrorAlert(alert, translate);
      return false;
    }

  return true;
}

async function setTokenInstalacion(instalacion: IInstalacion, explotaciones: ExplotacionesService, app: AppStatusService): Promise<void> {

  try {
    const response: IDatosToken | null = await firstValueFrom(
      explotaciones.postTokenInstalacionExplotacion(instalacion?.codInstalacion, ''),
    );

    if (response?.token?.accessToken) {
      app.ccaToken = response.token.accessToken;
    } else {
      throw new Error('Sin token de acceso');
    }
  } catch (error) {
    throw new Error('Token no recibido');
  }
}

async function setTokenComplete(instalacion: IInstalacion, codIdentific: string, explotaciones: ExplotacionesService,
  app: AppStatusService, alert: AlertService, translate: TranslateService): Promise<boolean> {

  try {
    const response: IDatosToken | null = await firstValueFrom(
      explotaciones.postTokenInstalacionExplotacion(instalacion?.codInstalacion, codIdentific),
    );

    if (response?.token?.accessToken) {
      app.ccaToken = response.token.accessToken;
      return true;
    } else {
      showTokenErrorAlert(alert, translate);
      return false;
    }
  } catch (error) {
    showTokenErrorAlert(alert, translate);
    return false;
  }
}

/**
 * Mostrar alerta de error de token
 */
function showTokenErrorAlert(alert: AlertService, translate: TranslateService) {
  alert.openAlert(EMessageTypes.error, {
    code: 0,
    header: translate.instant('ALERT.TOKEN_ERROR.HEADER'),
    message: translate.instant('ALERT.TOKEN_ERROR.MESSAGE'),
    actionButton: () => alert.dismiss(),
    actionButtonLabel: translate.instant('ALERT.TOKEN_ERROR.SUB_ACCION'),
  });
}

async function checkAltaExplotacion(instalacion: IInstalacionAmpliado | undefined, globalCampo: GlobalcampoService, storage: IStorageProvider): Promise<boolean> {
      if (instalacion?.explotaciones?.length) {
        const altaExplotacionKey: string = StorageKeys.ALTA_EXPLOTACION.name;
        const explotacionData = await firstValueFrom(storage.get<IAltaExplotacionData>(altaExplotacionKey));

        const matchingExplotacion: IExplotacionAmpliado | undefined = instalacion.explotaciones.find((explotacion) =>
          explotacion.codIdentific === explotacionData?.codIdentific,
      );
        const feedBack = matchingExplotacion?.feedbacks ?? [];

        if (feedBack.length > 0) {
          const unfinished = feedBack.some((feedback) => feedback.indCompletado === 'N');

          if (unfinished) {
            globalCampo.feedBack.set(feedBack);
            return true;
          } else {
            return false;
          }

        } else {
          return false;
        }
      } else {
        return true;
      }
}
