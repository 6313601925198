import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, ModalOptions, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map } from 'rxjs';
import { ActividadesMenuComponent } from 'src/app/components/modales/app-actividades-menu-modal/actividades-menu.component';
import { activadesCCA } from 'src/app/globalcampo/config/listado-actividades-cca.config';
import { estadoActividades } from '../../config/estado-actividades.config';
import { estadoAsesoramientoConf } from '../../config/estado-asesoramiento.config';
import { urls } from '../../config/urls';
import { actividadesEndPoints } from '../../enpoints/actividades.endpoint.v1';
import { EMessageTypes } from '../../enums/message-type.enum';
import { IActividadAgrariaFilter } from '../../interfaces/cca/actividades/agraria/actividad-agraria-filter.interface';
import { ICcaActividadCue } from '../../interfaces/cca/actividades/agraria/cca-actividad-cue.interface';
import { ICcaDuplicarActividadRespuesta } from '../../interfaces/cca/actividades/cca-duplicar-actividad-respuesta.interface';
import { ICcaErroreRespuesta } from '../../interfaces/cca/actividades/cca-errores-respuesta.interface';
import { IDatosCcaDgcCultivoListado } from '../../interfaces/cca/actividades/datos-cca-dgc-cultivo-listado';
import { ICcaActividadCueEdificacion } from '../../interfaces/cca/actividades/edificacion/cca-actividad-cue-edificacion.interface';
import { ICcaActividadCueTratamfito } from '../../interfaces/cca/actividades/fitosanitario/cca-actividad-cue-tratamiento-fitosanitario.interface';
import { MessageTypes } from '../../types/message-types.type';
import { AlertService } from '../alerts/alert.service';
import { AppStatusService } from '../app-status.service';


@Injectable({
  providedIn: 'root'
})
export class ActividadesService {

  private readonly _actSinEnviar: number = estadoActividades.SIN_ENVIAR.auxEstadoActividad;
  private readonly _actPendienteValidacion: number = estadoActividades.PENDIENTE_VALIDACION.auxEstadoActividad;
  public  readonly _actEnviadaConErrores: number = estadoActividades.VALIDADA_CON_ERRORES.auxEstadoActividad;
  private readonly _actValidada: number = estadoActividades.VALIDADA.auxEstadoActividad;
  private readonly _actErrorEnviar: number = estadoActividades.ERROR_ENVIO.auxEstadoActividad;

  constructor(
    private readonly http: HttpClient, private readonly modal: ModalController, private readonly router: Router, private readonly app: AppStatusService,
    private readonly _alert: AlertService, private readonly _trans: TranslateService, private readonly _navCtrl: NavController,
  ) { }


  /**
   * Registro de un nuevo tratamiento fitosanitario
   */
  public postTratatimentoFitosanitario(tratamiento: ICcaActividadCueTratamfito): Observable<ICcaActividadCueTratamfito|null> {
    return this.http.post<ICcaActividadCueTratamfito>(actividadesEndPoints.postTratamientoFitosanitario, tratamiento);
  }

  public getActTratatimentoFitosanitario(idActTraFito: string): Observable<ICcaActividadCueTratamfito> {
    return this.http.get<ICcaActividadCueTratamfito>(actividadesEndPoints.getActTratamFito(idActTraFito));
  }


  /**
   *  Obtiene los cultivos de una determinada explotación
   * @param idExplotacion  el Id de la explotación a consultar
   */
  public getCultivosExplotacion(idExplotacion: string, fchAct?: string): Observable<IDatosCcaDgcCultivoListado[]> {
    return this.http.get<IDatosCcaDgcCultivoListado[]>(
      actividadesEndPoints.getCultivosExplotacion(idExplotacion),
      {
        params: {
          fchAct: fchAct ?? '',
        },
      },
    ).pipe(
      map( cultivos => {
        if(!!cultivos && cultivos.length){
          cultivos.sort((a, b) => {
            if (!!cultivos && cultivos.length) {
              cultivos.sort((a, b) => (!!a.desProducto && !!b.desProducto) ? a.desProducto.localeCompare(b.desProducto) : 0);
            }
            return 0;
          });
        }
        return cultivos;
      })
    );
  }

  /**
   *  Obtiene los cultivos para una determinada actividad
   * @param idExplotacion  el Id de la explotación a consultar
   * @param codActividad codigo de la actividad que se está realizando
   * @param fchAct fecha en la que se realiza la actividad para obtener los cultivos disponibles
   */
    public getCultivosActividad(idExplotacion: string, codActividad: string, fchAct: string): Observable<IDatosCcaDgcCultivoListado[]>{
      return this.http.get<IDatosCcaDgcCultivoListado[]>(actividadesEndPoints.getCultivosActividad(idExplotacion),{params: {codActividad, fchAct}}).pipe(
        map( cultivos => {
          if(!!cultivos && cultivos.length){
            cultivos.sort((a, b) => {
              if (!!cultivos && cultivos.length) {
                cultivos.sort((a, b) => (!!a.desProducto && !!b.desProducto) ? a.desProducto.localeCompare(b.desProducto) : 0);
              }
              return 0;
            });
          }
          return cultivos;
        })
      );
    }

  /**
   * Obtiene los cultivos para una determinada actividad
   * @param idExplotacion  el Id de la explotación a consultar
   * @param codActividad codigo de la actividad que se está realizando
   * @param codSubActividad indica si tiene subactividad se usa en cosecha y siembra
   * @param fchAct fecha en la que se realiza la actividad para obtener los cultivos disponibles
   */
  public getCultivosActividadSubactividad(idExplotacion: string, codActividad: string, codSubActividad: string, fchAct: string): Observable<IDatosCcaDgcCultivoListado[]>{
    return this.http.get<IDatosCcaDgcCultivoListado[]>(actividadesEndPoints.getCultivosActividadSubactividad(idExplotacion),{params: {codActividad, codSubActividad, fchAct}}).pipe(
      map( cultivos => {
        if(!!cultivos && cultivos.length){
          cultivos.sort((a, b) => {
            if (!!cultivos && cultivos.length) {
              cultivos.sort((a, b) => (!!a.desProducto && !!b.desProducto) ? a.desProducto.localeCompare(b.desProducto) : 0);
            }
            return 0;
          });
        }
        return cultivos;
      })
    );
  }

  /**
   * Obtiene las actividades agrarias en base a los filtros definidos
   * @param filter  Filtros para obtener las actividades
   * @param paginado Si se quieren o no paginados
   * @param page Página de resultados
   * @param pageSize Tamaño de página
   * @param getAllResponse Obtiene toda la respuesta Http, no solo el body
   * @param orderBy Campo por el que se ordena
   * @param order Orden de la ordenación
   * @returns
   */
  public putActividadesAgrarias(filter: IActividadAgrariaFilter, paginado: boolean, page: number, pageSize: number, orderBy: string, order: string, getAllResponse = false): Observable<any> {
    let observe:any = 'body';
    const params = {
      page: page,
      pageSize: pageSize,
      paginado: paginado,
      orderBy: orderBy,
      order: order,
    };

    if(getAllResponse) {
      observe = 'response';
    }
    return this.http.put<any>(actividadesEndPoints.putActividadesAgrarias, filter, {params: params, observe: observe});
  }

  public getUltimaActividadAgraria(limit: number = 5): Observable<HttpResponse<ICcaActividadCue[]>> {
    return this.http.get<ICcaActividadCue[]>(actividadesEndPoints.getUltimaActividadAgraria(), { observe: 'response', params: { numResult: limit.toString() } });
  }

  /**
   * Muestra el listado de actividades disponibles para inciar el proceso de creación de una determinada actividad.
   */
  async openActividadesMenu(opts?: ModalOptions): Promise<void> {
    const modalView = await this.modal.create({
      component: ActividadesMenuComponent,
      canDismiss: true,
      id: 'modal',
      cssClass: 'actividades-menu',

    });

    modalView.present();

    const res = await modalView.onWillDismiss();
    if(res?.data?.codTabla) {
      this.app.setCurrentActividadCCA(res.data);
        const url = this.getActividadUrl(res.data.codLinea, res.data.id);
        if (url === urls.cca.actividad_desconocida) {
          this._alert.openAlert(EMessageTypes.info, {
            code: 0,
            header: this._trans.instant('ALERT.ACTIVIDAD_CONSTRUCCION.HEADER'),
            message: this._trans.instant('ALERT.ACTIVIDAD_CONSTRUCCION.MESSAGE', {actividad: this._trans.instant(res.data.desLinea)}),
            actionButton: () => this._alert.dismiss(),
            actionButtonLabel: this._trans.instant('ALERT.ACTIVIDAD_CONSTRUCCION.ACCION'),

          });
        } else {
          this._navCtrl.navigateRoot(url, { queryParamsHandling: 'merge', replaceUrl: true });
        }
    }

  }

  /**
   * Devuelve la ruta a la actividad indicada
   * @param codLineaActividad El codLinea de la actividad
   * @param id Id de la actividad
   */
  public getActividadUrl(codLineaActividad: string|undefined, id?: string|undefined): string {
    // Obtenemos la actividad con el codLinea.
    const actividad = activadesCCA.find(act => act.codLinea === codLineaActividad);
    // Obtenemos el link de la actividad, añadimos valor por defecto por si no la encontrasemos.
    let url = actividad?.link ?? urls.cca.actividad_desconocida;

    if(url !== urls.cca.actividad_desconocida && !!id){
      url += '/' + id;
    }
    return url;
  }

  eliminarActividadAgraria(id: string): Observable<void> {
    return this.http.delete<void>(actividadesEndPoints.deleteActAgraria(id));
  }

  /**
   * Duplicar actividad agraria
   * @param id
   * @param fchInicio Nueva fecha de inicio de la actividad duplicada
   * @param fchFin Nueva fecha de fin de la actividad duplicada
   */
  duplicarActividadAgraria(id: string, fchInicio: string, fchFin: string = ''): Observable<ICcaDuplicarActividadRespuesta> {
    return this.http.post<ICcaDuplicarActividadRespuesta>(actividadesEndPoints.duplicarActAgraria(id), {}, { params: { fchInicio, fchFin } });
  }

  /**
   * Obtiene las respuestas de error que puede tener la actividad
   * @param idAct identificador de la actividad
   * @returns
   */
  public getActividadRespuestas(idAct: string): Observable<ICcaErroreRespuesta[]|null> {
    return this.http.get<ICcaErroreRespuesta[]|null>(actividadesEndPoints.getActRespuestas(idAct));
  }

  /**
  * Registro de un nuevo tratamiento a edificiaciones e instalaciones
  */
  public postActividadEdificacion(tratamiento: ICcaActividadCueEdificacion): Observable<ICcaActividadCueEdificacion|null> {
    return this.http.post<ICcaActividadCueEdificacion>(actividadesEndPoints.postTratamientoEdificacion, tratamiento);
  }

  public getActividadEdificacion(idActTraEdificacion: string): Observable<ICcaActividadCueEdificacion> {
    return this.http.get<ICcaActividadCueEdificacion>(actividadesEndPoints.getActEdificacion(idActTraEdificacion));
  }


/**
   * Aplicar estado actividad según el código de estado de actividad
   * @param cod codigo estado de actividad
   * @returns
   */
  public claseEstado(cod: number | undefined): {estado: MessageTypes, desc: string } {
    let estado: {estado: MessageTypes, desc: string } = {
      estado: EMessageTypes.warn,
      desc: ''
    };
    switch (cod) {
      case this._actSinEnviar:
        estado.estado = EMessageTypes.warn;
        estado.desc = '';
        break;
      case this._actPendienteValidacion:
        estado.estado = EMessageTypes.info;
        estado.desc = this._trans.instant('CCA.CUADERNO_DIGITAL.ACT_REALIZADAS.ESTADO_ACTIVIDADES.PENDIENTE_VALIDACION_DESC');
        break;
      case this._actEnviadaConErrores:
      case this._actErrorEnviar:
        estado.estado = EMessageTypes.error;
        estado.desc = '';
        break;
      case this._actValidada:
        estado.estado = EMessageTypes.success;
        estado.desc = this._trans.instant('CCA.CUADERNO_DIGITAL.ACT_REALIZADAS.ESTADO_ACTIVIDADES.VALIDADA_DESC');
        break;
      default:
        estado.estado = EMessageTypes.warn;
        estado.desc = '';
        break;
    }
    return estado;
  }

  /**
   * Indica la clase en base al estado de asesoramiento
   * @param cod
   */
  public claseEstadoAsesoramiento(cod: number | undefined | null): { estado: MessageTypes, desc: string } {
    const estado: { estado: MessageTypes, desc: string } = {
      estado: EMessageTypes.warn,
      desc: '',
    };
    const translationKey = `CCA.CUADERNO_DIGITAL.ACT_REALIZADAS.ESTADO_ASESORAMIENTO.`;
    switch (cod) {
      case estadoAsesoramientoConf.SOLICITADO.codAuxTipo:
        estado.estado = EMessageTypes.warn;
        estado.desc = this._trans.instant(`${translationKey}${estadoAsesoramientoConf.SOLICITADO.desAuxTipo}`);
        break;
      case estadoAsesoramientoConf.AJUSTES.codAuxTipo:
        estado.estado = EMessageTypes.error;
        estado.desc = this._trans.instant(`${translationKey}${estadoAsesoramientoConf.AJUSTES.desAuxTipo}`);
        break;
      case estadoAsesoramientoConf.ASESORADA_OK.codAuxTipo:
        estado.estado = EMessageTypes.success;
        estado.desc = this._trans.instant(`${translationKey}${estadoAsesoramientoConf.ASESORADA_OK.desAuxTipo}`);
        break;
      case estadoAsesoramientoConf.NO_REQUIERE.codAuxTipo:
        estado.estado = EMessageTypes.success;
        estado.desc = this._trans.instant(`${translationKey}${estadoAsesoramientoConf.NO_REQUIERE.desAuxTipo}`);
        break;
      case estadoAsesoramientoConf.NO_SOLICITADO.codAuxTipo:
        estado.estado = EMessageTypes.info;
        estado.desc = this._trans.instant(`${translationKey}${estadoAsesoramientoConf.NO_SOLICITADO.desAuxTipo}`);
        break;
      default:
        estado.estado = EMessageTypes.warn;
        estado.desc = '';
        break;
    }
    return estado;
  }
}
