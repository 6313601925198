import { environment } from 'src/environments/environment';
import { ENDPOINTS_PREFIX } from '../config/endpoints.prefix.config';

const apiVx = 'v1';
export const logsEndPoints = {
    postLogs: `${environment.apiURL}${ENDPOINTS_PREFIX.api}app/${apiVx}/logger`,
    putLogs: `${environment.apiURL}${ENDPOINTS_PREFIX.api}app/${apiVx}/logger`,
    putAllLogs: `${environment.apiURL}${ENDPOINTS_PREFIX.api}app/${apiVx}/logger/all`,
    getLogs: (id:string|null ) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}app/${apiVx}/logger/${id}`,
};
