/*
Default - Campos de Olivo
Globalcampo - Somos Globalcaja
Globalcoffee - Globalcoffee
vinedo - viñedo
almendros - almendros en flor
*/

import { IAltaExplotacionData } from '../../interfaces/explotaciones/alta-explotacion-data';

export type themesAllowed = 'default' | 'globalcampo' | 'globalcoffee' | 'almendros' | 'vinedo' | 'trigo' | 'ocean' | 'atlantis' | 'rose' | 'cherry' | 'ice' | 'inferno' | 'cyberpunk' | 'gicoop';
export type mapsViewType = 'roadmap' | 'satellite' | 'hybrid' | 'terrain';
export type modosVisualizacion = 'light' | 'dark' | 'system';

export interface IProfile {
  userData?: any;
  mode?: modosVisualizacion;
  lastAccess?: Date | null;
  ModalWelcome?: Date;
  selectedMapType: mapsViewType;
  theme: themesAllowed;
  test: boolean;
  skipNotiAsesor: boolean;
  altaExplotacion?: IAltaExplotacionData;
}

export interface ITheme {
  type: themesAllowed;
  label: string;
  class: string;
  imgContentBorder: string;
  contentBackBackground: string;
  contentBackBorder: string;
  contentFrontBackground: string;
  contentFrontBorder: string;
}

/**
 * Valores de perfil por defecto
 */
export const profileDefaults: IProfile = {
  theme: 'globalcampo',
  selectedMapType: 'terrain',
  lastAccess: new Date,
  mode: 'light',
  test: false,
  skipNotiAsesor: false,
};


/**
 * Un array de objetos de tema utilizados en la aplicación.
 * Cuando se añada un nuevo tema aquí, hay que ir al archivo es.json, y en la línea 1800, lo añadimos también
 * Cada objeto de tema se ajusta a la interfaz ITheme y contiene las siguientes propiedades:
 */
export const themes: ITheme[] = [
  { type: 'default', label: 'PERFIL.TEMA.DEFAULT', class: '',
    imgContentBorder: 'rgba(255, 254, 249, 1)',
    contentBackBackground: 'rgba(110, 131, 68, 1)',
    contentBackBorder: 'rgba(136, 146, 26, 0.1)',
    contentFrontBackground: 'rgba(183, 115, 50, 1)',
    contentFrontBorder: 'rgba(255, 254, 249, 1)' },
  { type: 'globalcampo', label: 'PERFIL.TEMA.GC', class: '',
    imgContentBorder: 'rgba(248, 248, 248, 1)',
    contentBackBackground: 'rgba(0, 102, 79, 1)',
    contentBackBorder: 'rgba(71, 160, 118, 0.1)',
    contentFrontBackground: 'rgba(41, 163, 107, 1)',
    contentFrontBorder: 'rgba(248, 248, 248, 1)' },
    { type: 'vinedo', label: 'PERFIL.TEMA.VIN', class: '',
    imgContentBorder: 'rgba(255, 254, 249, 1)',
    contentBackBackground: 'rgba(77, 46, 77, 1)',
    contentBackBorder: 'rgba(136, 146, 26, 0.1)',
    contentFrontBackground: 'rgba(229, 141, 0, 1)',
    contentFrontBorder: 'rgba(255, 254, 249, 1)' },
  { type: 'globalcoffee', label: 'PERFIL.TEMA.GCF', class: '',
    imgContentBorder: 'rgba(255, 254, 249, 1)',
    contentBackBackground: 'rgba(131, 105, 88, 1)',
    contentBackBorder: 'rgba(136, 146, 26, 0.1)',
    contentFrontBackground: 'rgba(208, 136, 130, 1)',
    contentFrontBorder: 'rgba(255, 254, 249, 1)' },
  { type: 'almendros', label: 'PERFIL.TEMA.ALM', class: '',
    imgContentBorder: 'rgba(255, 254, 249, 1)',
    contentBackBackground: 'rgba(146, 80, 99, 1)',
    contentBackBorder: 'rgba(136, 146, 26, 0.1)',
    contentFrontBackground: 'rgba(236, 113, 144, 1)',
    contentFrontBorder: 'rgba(255, 254, 249, 1)' },
    { type: 'trigo', label: 'PERFIL.TEMA.TRI', class: '',
    imgContentBorder: 'rgba(255, 254, 249, 1)',
    contentBackBackground: 'rgba(92, 55, 10, 1)',
    contentBackBorder: 'rgba(136, 146, 26, 0.1)',
    contentFrontBackground: 'rgba(229, 172, 0, 1)',
    contentFrontBorder: 'rgba(255, 254, 249, 1)' },
    { type: 'ocean', label: 'PERFIL.TEMA.OCE', class: '',
    imgContentBorder: 'rgba(255, 254, 249, 1)',
    contentBackBackground: 'rgba(1, 58, 99, 1)',
    contentBackBorder: 'rgba(136, 146, 26, 0.1)',
    contentFrontBackground: 'rgba(247, 108, 94, 1)',
    contentFrontBorder: 'rgba(255, 254, 249, 1)' },
    { type: 'atlantis', label: 'PERFIL.TEMA.ATL', class: '',
    imgContentBorder: 'rgba(255, 254, 249, 1)',
    contentBackBackground: 'rgba(34, 80, 86, 1)',
    contentBackBorder: 'rgba(136, 146, 26, 0.1)',
    contentFrontBackground: 'rgba(209, 140, 71, 1)',
    contentFrontBorder: 'rgba(255, 254, 249, 1)' },
    { type: 'rose', label: 'PERFIL.TEMA.ROS', class: '',
    imgContentBorder: 'rgba(255, 254, 249, 1)',
    contentBackBackground: 'rgba(36, 46, 66, 1)',
    contentBackBorder: 'rgba(136, 146, 26, 0.1)',
    contentFrontBackground: 'rgba(138, 66, 122, 1)',
    contentFrontBorder: 'rgba(255, 254, 249, 1)' },
    { type: 'cherry', label: 'PERFIL.TEMA.CHE', class: '',
    imgContentBorder: 'rgba(255, 254, 249, 1)',
    contentBackBackground: 'rgba(49, 49, 51, 1)',
    contentBackBorder: 'rgba(136, 146, 26, 0.1)',
    contentFrontBackground: 'rgba(167, 150, 126, 1)',
    contentFrontBorder: 'rgba(255, 254, 249, 1)' },
    { type: 'ice', label: 'PERFIL.TEMA.ICE', class: '',
    imgContentBorder: 'rgba(255, 254, 249, 1)',
    contentBackBackground: 'rgba(31, 41, 50, 1)',
    contentBackBorder: 'rgba(136, 146, 26, 0.1)',
    contentFrontBackground: 'rgba(92, 166, 204, 1)',
    contentFrontBorder: 'rgba(255, 254, 249, 1)' },
    { type: 'inferno', label: 'PERFIL.TEMA.INF', class: '',
    imgContentBorder: 'rgba(255, 254, 249, 1)',
    contentBackBackground: 'rgba(55, 6, 6, 1)',
    contentBackBorder: 'rgba(136, 146, 26, 0.1)',
    contentFrontBackground: 'rgba(187, 17, 51, 1)',
    contentFrontBorder: 'rgba(255, 254, 249, 1)' },
    { type: 'cyberpunk', label: 'PERFIL.TEMA.CYB', class: '',
    imgContentBorder: 'rgba(255, 254, 249, 1)',
    contentBackBackground: 'rgba(32, 27, 50, 1)',
    contentBackBorder: 'rgba(136, 146, 26, 0.1)',
    contentFrontBackground: 'rgba(236, 70, 160, 1)',
    contentFrontBorder: 'rgba(255, 254, 249, 1)' },
];


