import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { from, map, Observable } from 'rxjs';
import { externalUrls } from '../../config/urls';
import { IAlertContent } from '../../interfaces/alerts/alert-content.interface';
import { AlertService } from '../alerts/alert.service';

@Injectable({
    providedIn: 'root',
})
export class LonjasRedirectionGuard {

    alertContent: IAlertContent = {
        header: 'Acceso a Las Lonjas',
        message: 'Las Lonjas todavía están en la versión anterior de Globalcampo. Te las vamos a mostrar en tu navegador web.',
        actionButton: this.redirectToLonjas(),
        actionButtonLabel: 'Ok',
        subActionButton: () => {},
        subActionButtonLabel: 'Cancelar',
        backdropDismiss: true,
        showIcon: false,
    };

    constructor(private readonly _alert: AlertService) {}

    canActivate( _next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {

        return from(this._alert.createAlert('alert', 'info', this.alertContent)).pipe(
            map(() => false),
        );
    }

    private redirectToLonjas(): Function {
        return () => {
            window.open(externalUrls.lonjas, '_self', 'noopener');
        };
    }
}
