<div id="gc-main-menu-content">
    @for(navLink of navLinks; track navLink) {
      <ion-button class="btn-s-accent-clear gc-content-btn" [ngClass]="{ 'active': linkActive(navLink.link ?? '')}"
        routerLink="{{navLink.link}}"
        (click)="navigationUrl(navLink, $event)"
        (keydown.enter)="navigationUrl(navLink, $event)" >
        {{ navLink.title | translate }}
      </ion-button>
    }
    <ion-button id="gc-content-btn" class="btn-s-accent-clear gc-content-btn" (click)="toggleDropdown()"  (keydown.enter)="toggleDropdown()">
      {{ 'MENU.MAIN.OTROS_SERVICIOS' | translate }}
      <ion-icon id="gc-btn-icon" slot="end" name="gc-arrow-flat-down"></ion-icon>
    </ion-button>
</div>
